<template>
  <v-row>
    <v-col cols="12" md="3">
      <AdminMenu></AdminMenu>
    </v-col>
    <v-col cols="12" md="9" class="adminBg">
      <v-layout wrap class="mt-3">
        <v-col cols="12">
          <h4>Manage Logistics</h4>
        </v-col>
      </v-layout>
      <v-layout wrap class="mt-5">
        <v-col cols="12" md="6">
          <v-layout class="boxStat" style="border: 1px solid #ffd809;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">All Logistics Companies</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{logisticsCompanyData.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>

        <v-col cols="12" md="6">
          <v-layout class="boxStat" style="border: 1px solid #72BD61;">
            <v-col cols="12">
              <v-layout>
                <v-col cols="12">
                  <span class="ml-5">Active Logistics Companies</span>
                </v-col>
              </v-layout>
              <v-layout>
                <v-col cols="6">
                  <span class="text-h5 ml-5"><b>{{activeLogisticsCompanyData.length}}</b></span>
                </v-col>
                <v-col cols="3" align="right">
                  <v-btn small>
                    <v-icon size="20">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>

      <v-layout wrap>
        <v-col cols="12">
          <v-layout wrap>
            <v-col cols="4">
              <v-dialog v-model="dialogLogisticsAccount" width="450">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on"
                    >Create Logistics
                    <v-icon class="ml-3" color="#72BD61"
                      >mdi-dolly</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-text>
                    <v-layout>
                      <v-btn icon @click="dialogLogisticsAccount = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-layout>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-icon color="#72BD61" size="40"
                          >mdi-truck-outline</v-icon
                        >
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col class="text-h6" cols="12" align="center">
                        Add a logistics company
                      </v-col>
                    </v-layout>
                    <v-row>
                      <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="regData.name"
                    label="Company Name"
                    append-icon="mdi-warehouse"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="regData.contactPerson"
                    label="Contact Person"
                    append-icon="mdi-account-settings"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    v-model="regData.email"
                    label="Email"
                    append-icon="mdi-email"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    name="password"
                    label="Password"
                    outlined
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    id="password"
                    dense
                    v-model="regData.password"
                    @click:append="show3 = !show3"
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row class="d-flex">
                        <v-text-field
                          v-model="regData.phoneNumber"
                          outlined
                          dense
                          label="Phone Number"
                          append-icon="mdi-phone"
                        ></v-text-field>
                </v-row>

                <v-row>
                        <v-text-field
                          v-model="regData.address"
                          outlined
                          dense
                          label="Address"
                          append-icon="mdi-map-marker"
                        ></v-text-field>
                </v-row>

                <v-row>
                        <v-select
                          :items="country"
                          label="Select Country"
                          v-model="regData.country"
                          append-icon="mdi-flag"
                          outlined
                          dense
                          item-text="name"
                          @change="getCountryStates"
                        ></v-select>
                </v-row>

                <v-row>
                        <v-select
                          :items="states"
                          label="Select State"
                          append-icon="mdi-map-marker"
                          outlined
                          dense
                          item-text="name"
                          v-model="regData.state"
                        ></v-select>
                </v-row>


               

                <v-layout color="#BD2121">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                </v-layout>


              </v-col>
                    </v-row>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-btn class="buttonStyle"  @click.native="submitReg">
                          Create Account
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogSuccess" width="350" persistent>
                <v-card>
                  <v-card-text>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-icon color="#72BD61" size="50"
                          >mdi-check-circle</v-icon
                        >
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col class="text-h6" cols="12" align="center">
                        Account Created Successfully
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        You have Successfully created a logistic company
                        account. Login details have been emailed to them
                        accordingly
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-btn
                          class="buttonStyle"
                          @click="dialogSuccess = false"
                        >
                          Ok
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogSingleLogisticDetail" width="550" persistent>
                <v-card>
                  <v-card-text>
                    <v-layout>
                      <v-btn icon @click="dialogSingleLogisticDetail = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-layout>
                    <v-layout>
                      <v-col cols="12" align="center">
                        <v-icon color="#72BD61" size="80"
                          >mdi-truck-outline</v-icon
                        >
                      </v-col>
                    </v-layout>
                    <v-layout wrap>
                      <v-col class="text-h6" cols="12" align="center">
                       About logistics company
                      </v-col>
                    </v-layout>
                    <v-row>
                      <v-col cols="12">
                        <v-layout wrap>
                          <v-col cols="12" md="6">
                            <v-text-field
                              dense
                              v-model="singleLogisticCompany.name"
                              prepend-inner-icon="mdi-truck"
                              label="Company name"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              dense 
                              v-model="singleLogisticCompany.contactPerson"
                              prepend-inner-icon="mdi-account"
                              label="Contact Person"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-layout>

                        <v-layout wrap>
                          <v-col cols="12" md="6">
                            <v-text-field
                            dense
                            v-model="singleLogisticCompany.phoneNumber"
                            prepend-inner-icon="mdi-phone"
                            label="Phone Number"
                            readonly
                          ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                            dense 
                            v-model="singleLogisticCompany.email"
                            prepend-inner-icon="mdi-email"
                            label="Email Address"
                            readonly
                            ></v-text-field>
                          </v-col>
                        </v-layout>

                        <v-layout wrap>
                          <v-col cols="12">
                            <v-text-field
                            dense 
                            v-model="singleLogisticCompany.address"
                            prepend-inner-icon="mdi-map-marker"
                            label="Address"
                            readonly
                          ></v-text-field>
                          </v-col>
                        </v-layout>

                        <v-layout wrap>
                          <v-col cols="12" md="6">
                            <v-text-field
                            dense 
                            v-model="singleLogisticCompany.state"
                            prepend-inner-icon="mdi-store-marker-outline"
                            label="State"
                            readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                            dense
                            v-model="singleLogisticCompany.country"
                            prepend-inner-icon="mdi-map-marker-distance"
                            label="Country"
                            readonly
                            ></v-text-field>
                          </v-col>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-layout wrap>
                          <v-col cols="12" md="6">
                            <v-text-field
                            dense 
                            v-model="singleLogisticCompany.pricePerKg"
                            prepend-inner-icon="mdi-currency-usd"
                            label="Price Per Kg"
                            readonly
                            ></v-text-field>
                          </v-col>
                           <v-col cols="12" md="6">
                            <v-text-field
                            dense 
                            v-model="singleLogisticCompany.pricePerKm"
                            prepend-inner-icon="mdi-currency-usd"
                            label="Price Per Km"
                            readonly
                            ></v-text-field>
                          </v-col>
                        </v-layout>

                         <v-layout wrap>
                          <v-col cols="12">
                            <h6 v-if="singleLogisticCompany.trackingAvailable">{{singleLogisticCompany.name}} allows item tracking on system.</h6>
                            <h6 v-if="singleLogisticCompany.trackingAvailable == false">{{singleLogisticCompany.name}} do not allows item tracking on system.</h6>
                          </v-col>
                        </v-layout>

                       
                      </v-col>
                    </v-row>

                    <v-layout wrap class="mt-3">
                        <v-progress-linear
                            class="mt-3"
                            color="#7CBF46"
                            v-show="loader"
                            :indeterminate="true"
                        ></v-progress-linear>
                    </v-layout>

                    <v-layout wrap>
                      <v-col cols="12" align="center">
                        <v-btn class="buttonStyle" @click="updateCompanyStatus(singleLogisticCompany)">
                         <span v-if="singleLogisticCompany.isActive == false">Activate Account</span>
                         <span v-if="singleLogisticCompany.isActive">Deactivate Account</span>
                        </v-btn>
                      </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-dialog>

            </v-col>
          </v-layout>
          <v-layout
            wrap
            class="whiteBg"
            v-if="logisticsCompanyData.length >= 1"
          >
            <v-col cols="12">
              <v-layout wrap>
                <h5>All Logistic Companies</h5>
              </v-layout>

              <v-layout wrap>
                <v-col cols="12">
                  <v-simple-table fixed-header height="300px">
                    <template v-slot:default>
                      <thead>
                        
                        <tr>
                           <th class="text-left">
                            ID
                          </th>
                          <th class="text-left">
                            Company Name
                          </th>
                          <th class="text-left">
                            Address
                          </th>
                          <th class="text-left">
                            Email
                          </th>
                          <th class="text-left">
                            Priority
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in logisticsCompanyData" :key="item.logisticsCompanyId">
                          <td style="font-size:12px;">
                            <v-layout wrap>
                              <v-col cols="12">
                                <span
                                  ><b>
                                    {{item.logisticsCompanyId}}
                                    </b
                                  ></span
                                >
                              </v-col>
                            </v-layout>
                          </td>
                          <td style="font-size:12px;">
                                <span>
                                    {{item.name}}
                                </span>
                          </td>

                          <td style="font-size:12px;">
                                <span
                                  >
                                    {{item.address}}
                                   </span
                                >
                          </td>

                          <td style="font-size:12px;">
                                <span
                                  >
                                    {{item.email}}
                                   </span
                                >
                          </td>
                         
                        
                          <td style="font-size:12px;">
                            <v-layout wrap>
                              <v-col cols="9">
                                <v-btn rounded small color="#72bd61" dark
                                 @click="fetchCompanyDetail(item)"
                                  >Details</v-btn
                                >
                              </v-col>
                            </v-layout>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import AdminMenu from "../others/AdminMenu";
import { Country, State } from "country-state-city";

export default {
  name: "AdminTransactionsModule",
  components: {
    AdminMenu,
  },
  data() {
    return {
      dialogLogisticsAccount: false,
      dialogSingleLogisticDetail: false,
      dialogSuccess: false,
      loader:false,
      logisticsCompanyData: [],
      activeLogisticsCompanyData: [],
      singleLogisticCompany:{},
      roles: [],
      systemSetting: {
        systemCurrency: "₦",
      },
      regData: {
        name: "",
        contactPerson: "",
        email: "",
        password: "",
        phoneNumber: "",
        address: "",
        country: "",
        state: "",
      },
      errorMessage: "",
      show3: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      country: [],
      states: [],
      cities: [],
      selectedCountry: {},
      
    };
  },
  created() {
    this.getLogisticsCompanies();
    this.getCountry();
  },
  methods: {
    getLogisticsCompanies() {
      this.$http
        .get(`${process.env.VUE_APP_URL}logistics`)
        .then((response) => {
          this.logisticsCompanyData = response.data.data;
          response.data.data.forEach(element => {
              if(element.isActive){
                this.activeLogisticsCompanyData.push(element);
              }
          });
        });
    },
    getCountry() {
      this.country = Country.getAllCountries();
    },
    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },
    submitReg() {
      this.loader = true;
      this.errorMessage = "";

      this.$http
        .post(`${process.env.VUE_APP_URL}logistics`, this.regData)
        .then(() => {
          this.loader = false;
          this.dialogLogisticsAccount = false;
          this.dialogSuccess = true;
        })
        .catch((error) => {
          this.loader = false;
          if (error.response.data.data.length >= 1) {
            this.errorMessage = error.response.data.data.toString();
          }
        });
    },
    fetchCompanyDetail(item) {
        this.singleLogisticCompany = item;
        this.dialogSingleLogisticDetail = true;
    },
    updateCompanyStatus(singleLogisticCompany)
    {
        this.loader = true;
        
        const logisticsCompanyId = singleLogisticCompany.logisticsCompanyId;
        
        const data = {
          isActive: !singleLogisticCompany.isActive
        }

        this.$http
        .post(`${process.env.VUE_APP_URL}change-logistics-status/` + logisticsCompanyId, data)
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    }
  },
};
</script>
<style scoped>
.adminBg {
  background: #e5e5e5;
}

.whiteBg {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
}

.boxStat {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 5px;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
